import React from 'react'
import MoreInfo from './BudMoreInfoSection'

const budContentSection = ({ system, elements }) => (
  <section className="main style1">
    <div className="grid-wrapper">
      <div className="col-12">
        <header className="major">
          <h3>BUD專項基金(BUD)</h3>
        </header>
        <p>BUD專項基金旨在資助個別香港企業推行有關發展品牌、升級轉型和拓展營銷的項目，從而提升他們在內地市場及與香港簽署自貿協定的經濟體的競爭力，促進他們在內地及自貿協定市場的發展。每次申請一個項目最高可得到100萬支助！ 每間公司累計資助上限為港幣400萬元。2021年財政預算案優化至上限600萬元!!</p>
        <MoreInfo />
      </div>
    </div>
  </section>
)

export default budContentSection
