import React from "react"
import Helmet from 'react-helmet'
import '../assets/scss/main.scss'
import BudContentSection from '../components/BudContentSection'
import CtaSection from '../components/CTASection'
import SliderBanner from '../components/SliderBanner'
import GlobalValues from '../components/GlobalValues'

class BudPage extends React.Component {
  render() {
    let imageList =[]
    // for (var i = 0; i < 3; i++) {
      imageList.push(require("../assets/images/sealcave_banner_bud.png"))
    // }
    return (
      <div>
        <Helmet title="BUD專項基金(BUD)" >
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="keywords" content="Sealcave, Seal Cave, BUD顧問,BUD顧問服務,bud 基金,bud 專項 基金,bud fund,bud 申請,bud hk,bud 專項基金,bud 計劃,bud 項目,bud 內地 專項 基金,中小企資助,專項 基金,香港 bud,申請 bud,bud fund hk,bud funding,bud 基金,bud 600 萬,bud 400 萬,資助基金,政府支援基金BUD,基金資助,申請基金資助,bud專項基金申請,發展品牌升級轉型及拓展內銷市場的專項基金,Dedicated fund on branding, Upgrading and Domestic sales"/>
        <meta name="description" content="Professional TVP/ BUD Funding consulting service help over hundred clients overcome obstacles successfully."></meta>
        {
          <script async src={GlobalValues.gtag_link}></script>
        }
        {
          <script>
            {GlobalValues.gtag_config}
          </script>
        }

        </Helmet>
        <SliderBanner images={imageList}/>
        <BudContentSection />
        <CtaSection />
      </div>
    )
  }
}
export default BudPage
