import React, { Component, Fragment} from 'react'
import Collapse, { Panel } from 'rc-collapse'

export default class BudMoreInfoSection extends Component {
    render() {
        return (
            <div className="col-12">
            <header className="major">
                <h3>更多資料</h3>
            </header>
                <Collapse accordion={true}>
                    <Panel header="提交申請的所需文件" showArrow={false}>
                        <ul>
                            <li>(i) 商業登記證副本；</li>
                            <li>(ii) 顯示持有 30%或以上擁有權的負責人／股東個人資料的證明文件副本 (如商業登記署表格 1(a)、公司註冊處周年申報表 (表格 AR1)等)；</li>
                            <li>(iii) 證明申請企業在香港擁有實質業務的文件副本 (請參考 附件二 中可作為證明文件的例子);</li>
                            <li>(iv) 申請企業上年度營業總額的證明文件副本（本年度成立的申請企業毋須提供);</li>
                            <li>(v) 申請企業與負責執行項目的內地業務單位的直接投資關係證明文件副本，例如: 有效營業執照、雙方簽署的有效來料加工合約等（只適用於涉及內地單位執行項目申請）;以及</li>
                            <li>(vi) 介紹申請企業業務/產品/服務的宣傳單張/印刷品。</li>
                        </ul>
                    </Panel>
                    <Panel header="計劃涵蓋的項目列表" showArrow={false}>
                        <b>項目措施:</b>
                        <ul>
                            <li>新設業務單位的相關開支 【不可多於項目總開支20%】</li>
                            <li>額外增聘員工 【不可多於項目總開支50%】</li>
                            <li>購買機器、設備及模具 【不可多於項目總開支70%】</li>
                            <li>產品樣本 【不可多於項目總開支30%】</li>
                            <li>廣告推廣 【不可多於項目總開支50%】</li>
                            <li>展覽會(包括虛擬展覽會)及宣傳活動之相關貨運、交通及住宿 【相關差旅費不可多於項目總開支20%】</li>
                            <li>設計/製作宣傳品【/】</li>
                            <li>電子商貿平台/網頁 【優化網頁的開支上限為港幣10萬】</li>
                            <li>流動應用程式(推廣用途) 【不可多於項目總開支50%】</li>
                            <li>檢測及認證服務 【/】</li>
                            <li>項目直接相關的專利和商標註冊 【每個計劃的累計資助上限為港幣30萬】</li>
                            <li>為獲批項目所需的帳目外聘核數費 【全額資助，每次最多港幣1萬，並計入企業的資助上限】</li>
                        </ul>
                    </Panel>
                    <Panel header="資助用途" showArrow={false}>
                        <b>1. 發展品牌</b>
                        <ul>
                            <li>(A) 品牌發展策略和定位 – 企業品牌願景、產品及服務策劃；</li>
                            <li>(B) 品牌建立、設計和傳訊 – 品牌形象及風格發展、品牌重建；</li>
                            <li>(C) 品牌管理 – 品牌評估、品牌保護；以及</li>
                            <li>(D) 品牌監控 – 品牌產權研究及品牌可持續性調查。</li>
                        </ul>
                        <b>2. 升級轉型</b>
                        <ul>
                            <li>(A) 商業模式升級轉型 – 由原設備製造商(OEM)轉化為原設計製造商(ODM)及／或自有品牌製造商(OBM)；</li>
                            <li>(B) 產品創新及重新定位 – 產品策略、新產品開發；</li>
                            <li>(C) 物料管理 – 供應鏈規劃及執行；</li>
                            <li>(D) 技術升級 – 製造技術升級、流程及業務自動化；</li>
                            <li>(E) 管理升級 – 世界級管理系統、業務流程重組、品質改善；以及</li>
                            <li>(F) 物流管理 – 倉儲管理、車隊管理及分銷管理系統的提升。</li>
                        </ul>
                        <b>3. 拓展內銷</b>
                        <ul>
                            <li>(A) 內銷策略規劃– 內銷策略制定；</li>
                            <li>(B) 內銷業務營運管理 –營運轉型；</li>
                            <li>(C) 內銷渠道管理 – 市場推廣策略及研究、銷售及分銷渠道開發、宣傳及推廣活動實施；</li>
                            <li>(D) 內銷團隊建立及管理 – 員工發展及績效管理。</li>
                        </ul>
                    </Panel>
                    <Panel header="資助金額" showArrow={false}>
                        <ul>
                            <li>2020年1月20日起BUD首期撥款最高75%，而企業須以現金形式承擔不少於該項目總核准開支的 50%。每家企業累計上限港幣$400萬元，原定內地及自貿計劃各有200萬元資助限制，在2020年4月29日取消該限制，即單一計劃可以用盡上限$400萬元的資助。免除獲資助企業開設及維持獨立的計息帳戶，以及於收取首期搬款前須存入對等資金於該帳戶的要求。申請撥款及收款程序:企業入表後翌日可以起動項目，首期撥款在入表後三至五個月後到帳，最終撥款則在項目完成後一至兩個月內批出。</li>
                        </ul>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}